@use 'sass-mq/mq' as *;
@use '@horizon/tokens/scss/partials/color' as *;
@use '@horizon/tokens/scss/partials/spacing' as *;
@use 'variables' as *;

@mixin header-footer-menu-open() {
    visibility: visible;
    opacity: 1;
}

@mixin header-footer-menu-hover-target($height: 22px, $right: 0, $top: 0, $width: $MENU_MIN_WIDTH) {
    background: transparent;
    border-radius: 0;
    content: '';
    display: block;
    height: $height;
    width: $width;
    top: $top;
    right: $right;
    position: absolute;
    z-index: $MENU_HOVER_TARGET_Z_INDEX;
}

#header,
#footer {

    hr {
        margin-left: 0;
        margin-right: 0;
    }

    .header-footer-menu {
        border-color: transparent;
        box-shadow: 0 8px 12px 0 rgba(42, 52, 61, 0.16);
        right: 0;
        min-width: $MENU_MIN_WIDTH;
        max-width: $MENU_MAX_WIDTH;
        background-color: white;
        border-radius: $MENU_BORDER_RADIUS;
        visibility: hidden;
        position: absolute;
        opacity: 0;
        z-index: $PRIMARY_MENU_Z_INDEX;
        margin-top: $hzn-spacing-large;

        .header-link {
            font-size: $SMALL_FONT_SIZE;
            color: $hzn-color-text-neutral;

            &--priority {
                color: $hzn-color-text-critical;
            }
        }

        &-list-item {
            list-style: none;
            margin: 0;
            padding: 0;

            &:focus,
            &.focus,
            &:hover {
                background-color: $hzn-color-background-subdued;
            }
        }

        &-list {
            background-color: white;
            padding: $hzn-spacing-xsmall 0;
            white-space: nowrap;

            & .focus {
                background-color: $hzn-color-background-subdued;
            }

            .header-footer-menu-list-item {
                margin: 0;
                list-style: none;

                .header-link {
                    color: #2a343d;
                    height: 40px;
                    cursor: pointer;
                    user-select: none;
                    display: flex;
                    position: relative;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 0 16px;
                    overflow: hidden;

                    &:hover {
                        background-color: $hzn-color-background-subdued;
                    }
                }
            }

            .header-footer-menu-divider {
                display: block;
                border: 0;
                border-top: 1px solid $hzn-color-border-neutral-weak;
            }
        }
    }
}
