/*
    From CSS Tricks: https://css-tricks.com/comparing-various-ways-to-hide-things-in-css/
*/
.visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
