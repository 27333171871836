@use 'sass:list';
@use 'sass-mq/mq' as *;
@use '@horizon/tokens/scss/partials/color' as *;
@use '@horizon/tokens/scss/partials/fontFamily' as *;
@use '@horizon/tokens/scss/partials/fontWeight' as *;
@use '@horizon/tokens/scss/partials/spacing' as *;
@use 'variables' as *;
@use 'mixins' as *;
@use 'menu' as *;

$INPUT_BORDER_COLOR: $hzn-color-border-neutral-weak;
$INPUT_ACTIVE_BORDER_COLOR: $hzn-color-border-brand-weak-dark;
$INPUT_BACKGROUND_COLOR: #f5f6f7;

#header,
#footer {
    .header-search-bar,
    .header-footer-store-search-bar {
        .header-footer-icon-button {
            background-color: $INPUT_BACKGROUND_COLOR;
        }

        &-note {
            margin: 8px;
            padding: 8px;
            border-width: 0 0 0 8px;
            border-radius: 4px;
            border-style: solid;
            border-color: #2a343d;
            color: #2a343d;
            background-color: #f5f6f7;
            font-weight: bold;
        }

        &-button-container {
            height: $SEARCH_BAR_HEIGHT;
            display: flex;

            .header-footer-icon-button {
                border-top-right-radius: $INPUT_BORDER_RADIUS;
                border-bottom-right-radius: $INPUT_BORDER_RADIUS;
                border: 1px solid $INPUT_BORDER_COLOR;
                border-left: none;

                &:focus {
                    @include focus-styles();
                }
            }
        }

        &-container {
            display: flex;
            align-items: center;
            height: $SEARCH_BAR_HEIGHT;
            width: 100%;
            border-radius: $INPUT_BORDER_RADIUS;
            position: relative;

            &:hover,
            &:focus {
                box-shadow: none;
            }
        }

        &-form {
            flex-direction: column;
            max-width: $CONTENT_MAX_WIDTH;
            margin: auto;
            display: flex;
            justify-content: center;
            width: 100%;
            background-color: $hzn-color-background;
            padding: 0 $hzn-spacing-base $hzn-spacing-base;

            @include mq($from: 900px, $until: $CONTENT_MAX_WIDTH_WITH_PADDING) {
                padding: 0 $hzn-spacing-medium $hzn-spacing-base;
            }

            @include mq($from: $CONTENT_MAX_WIDTH_WITH_PADDING) {
                padding: 0 0 $hzn-spacing-base;
            }
        }

        &-form-fieldset {
            border: 0;
            margin: 0;
            padding: 0;
        }

        &-input {
            border: 1px solid $INPUT_BORDER_COLOR;
            border-radius: $INPUT_BORDER_RADIUS;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: none;
            background-color: $INPUT_BACKGROUND_COLOR;
            font-size: $BASE_FONT_SIZE;
            font-family: $hzn-fontfamily-body;
            order: 0;
            height: $SEARCH_BAR_HEIGHT;
            color: $hzn-color-text-neutral;
            flex-grow: 1;
            min-width: 0;
            outline: none;
            padding: 0 0 0 $hzn-spacing-base;
            width: 100%;
            -webkit-appearance: none;

            &:hover,
            &:active,
            &:focus {
                & + .header-search-bar-button-container > .header-footer-icon-button {
                    border: 1px solid $hzn-color-tone-info;
                    background-color: $DEFAULT_SEARCH_BAR_OUTLINE_COLOR;
                    box-shadow: $DEFAULT_SEARCH_BAR_BUTTON_OUTLINE;
                    border-left: none;
                    fill: #005ab8 !important;
                }

                border: 1px solid $hzn-color-tone-info;
                background-color: $DEFAULT_SEARCH_BAR_OUTLINE_COLOR;
                box-shadow: $DEFAULT_SEARCH_BAR_OUTLINE;
                border-right: none;
            }

            &::placeholder {
                color: #565d65;
            }

            &:hover {
                &::placeholder {
                    color: #aaafb3;
                }
            }

            &:active,
            &:focus {
                &::placeholder {
                    color: #aaafb3;
                }
            }

            // clears the 'X' from IE
            &[type='search']::-ms-clear {
                display: none;
                width: 0;
                height: 0;
            }

            &[type='search']::-ms-reveal {
                display: none;
                width: 0;
                height: 0;
            }
            // clears the 'X' from Chrome
            &[type='search']::-webkit-search-decoration,
            &[type='search']::-webkit-search-cancel-button,
            &[type='search']::-webkit-search-results-button,
            &[type='search']::-webkit-search-results-decoration {
                display: none;
            }
        }

        #header-search-button,
        #header-store-search-button,
        #footer-store-search-button {
            order: 2;
            padding-left: $hzn-spacing-base;
            padding-right: $hzn-spacing-base;
            fill: #565d65;
            flex-shrink: 0;
        }
    }

    .header-search-bar {
        &--active {
            .header-search-bar-container {
                border-color: transparent;
                box-shadow: 0 8px 12px 0 rgba(42, 52, 61, 0.16);
            }

            .header-search-bar-input {
                border-bottom-left-radius: 0;
                border-bottom: 1px solid transparent;
            }

            .header-search-bar-menu-hr {
                display: none;
            }

            #header-search-button {
                border-bottom-right-radius: 0;
                border-bottom: 1px solid transparent;
                fill: #005ab8;
            }

            .header-footer-menu {
                &--open {
                    @include header-footer-menu-open();
                }
            }
        }

        .header-footer-menu {
            border-radius: $INPUT_BORDER_RADIUS;
            border: 1px solid $INPUT_ACTIVE_BORDER_COLOR;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: none;
            z-index: $SECONDARY_MENU_Z_INDEX;

            @include mq($until: 767px) {
                margin-top: -26px;

                &-container {
                    position: absolute;
                    top: 120px;
                    left: 16px;
                    right: 16px;
                }
            }

            @include mq($from: 767px) {
                margin-top: -2px;

                &-container {
                    position: absolute;
                    top: 120px;
                    left: 16px;
                    right: 16px;
                }
            }

            @include mq($from: 900px) {
                margin-top: -20px;

                &-container {
                    position: absolute;
                    top: 138px;
                    left: $hzn-spacing-medium;
                    right: $hzn-spacing-medium;
                }
            }

            @include mq($until: $CONTENT_MAX_WIDTH) {
                width: 100%;
                min-width: auto;
                left: 0;
                right: 0;
                max-width: none;
            }

            @include mq($from: $CONTENT_MAX_WIDTH) {
                margin-top: -18px;
                left: 0;
                max-width: 1152px;
                width: 100%;
                margin-right: auto;
                margin-left: auto;

                &-container {
                    position: relative;
                    top: 0;
                    left: 0;
                    right: 0;
                    max-width: $CONTENT_MAX_WIDTH;
                    margin: auto;
                }
            }

            @include mq($from: $CONTENT_MAX_WIDTH_WITH_PADDING) {
                max-width: $CONTENT_MAX_WIDTH;
            }

            &-list {
                padding: 0;
                border-bottom-right-radius: $MENU_BORDER_RADIUS;
                border-bottom-left-radius: $MENU_BORDER_RADIUS;

                &-item {
                    font-weight: $hzn-fontweight-base;
                    font-family: $hzn-fontfamily-body;
                    letter-spacing: normal;
                    font-size: 1rem; // $hzn-fontsize-body-base
                    line-height: 1.5625;
                    color: $hzn-color-border-neutral;
                    height: auto;
                    border: solid 1px transparent;
                    border-left: none;
                    border-right: none;

                    &.search-for-highlight {
                        .menu-item-link {
                            font-weight: $hzn-fontweight-base;
                            font-family: $hzn-fontfamily-body;

                            .search-for-text {
                                color: $hzn-color-border-neutral;
                            }
                        }

                        &:hover {
                            background-color: #e0f0ff;
                        }
                    }

                    &.default-highlight {
                        background-color: #f0f8ff;
                    }

                    &.recent-search {
                        :first-child {
                            flex-direction: row;
                        }

                        .filters {
                            color: $hzn-color-text-subdued;
                            margin-left: $hzn-spacing-xsmall;
                            font-style: italic;
                            font-size: 14px;
                            margin-top: 2px;
                        }
                    }

                    .menu-item-link {
                        color: $hzn-color-text-neutral;
                        text-decoration: none;
                        display: flex;
                        flex-direction: column;
                        padding: 8px 16px 8px;
                        white-space: break-spaces;
                        font-size: 16px;

                        .saved-search-label {
                            font-weight: 400;
                        }

                        &:hover {
                            color: #1c2733;
                        }

                        &:focus-visible {
                            outline-color: $hzn-color-text-info;
                        }
                    }

                    .menu-item-text {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        .article-text {
                            border-bottom: 1px solid #1773cf;
                            color: #1773cf;
                        }

                        .optimized-article {
                            border-bottom: none;
                            font-weight: 700;
                            color: $hzn-color-text-info-on-brand-accent;
                        }

                        .menu-item-inner-text {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    .list-item-location {
                        font-style: italic;
                    }

                    .list-item-description {
                        color: $hzn-color-text-subdued;
                        text-decoration: none;
                        font-style: italic;
                        font-size: 14px;
                    }

                    .list-item-question {
                        font-style: italic;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 25px;
                        color: #3e464f;
                        margin: 4px 0;
                    }

                    .list-item-answer {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: #3e464f;
                    }

                    .list-item-link {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: #1871c9;
                        text-decoration: none;
                    }

                    .questionContainer {
                        display: flex;
                        flex-direction: column;
                        margin: 0 32px;
                    }

                    .optimized-link {
                        padding: 8px 16px 8px 16px;
                    }
                }
            }

            &-heading {
                font-weight: $hzn-fontweight-bold;
                font-family: $hzn-fontfamily-body;
                letter-spacing: normal;
                line-height: 1.42857;
                color: $hzn-color-text-neutral;
                font-size: 16px;
                display: block;
                text-transform: uppercase;
                padding: 0 $hzn-spacing-base 8px;
            }

            .optimized-heading {
                color: $hzn-color-text-neutral;
            }

            .qa-heading {
                padding: 0 $hzn-spacing-base 0;
            }

            .qa-section {
                background-color: #f5f6f7;
                border-radius: 0 0 8px 8px;
                padding-bottom: 16px;
            }

            .qa-bg {
                background: transparent;

                &:hover {
                    background-color: transparent;
                }
            }

            &-section {
                padding: 24px 0;

                &.has-note {
                    padding: 16px 0;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid $hzn-color-border-neutral-weak;
                }
            }
        }
    }

    .header-footer-store-search-bar {
        background-color: $hzn-color-background;
        border-radius: $MENU_BORDER_RADIUS;
        color: $hzn-color-text-neutral;
        padding: $hzn-spacing-base;
        padding-top: 8px;

        &-form {
            padding: 0;
        }

        &-input {
            height: $STORE_SEARCH_BAR_HEIGHT;
            padding-left: $hzn-spacing-xsmall;
        }

        &:nth-child(2) {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        #header-store-search-header,
        #footer-store-search-header {
            color: $hzn-color-text-info;
            font-size: $XSMALL_FONT_SIZE;
        }

        .header-search-bar-button-container {
            height: $STORE_SEARCH_BAR_HEIGHT;
        }
    }
}

#header {
    #header-search-bar-menu {
        border-width: 0 2px 2px;
        border-color: $hzn-color-text-info;
        border-style: solid;
        border-top: 1px solid $hzn-color-border-neutral-weak;
    }
}

// hide until js adds a parent class to activate
.header-search-animated-categories {
    display: none;
    height: $STORE_SEARCH_BAR_HEIGHT;
}

.search-bar-animated {
    .header-search-bar-input {
        pointer-events: auto;
    }

    $categories : (
        ('make')
        ('model')
        ('keyword')
        ('make')
        ('model')
        ('keyword')
        ('make')
        ('model')
        ('keyword')
        ('make, model, or keyword')
    );

    $GLOBAL_TIMELINE_DELAY: 0s; /* time paused before playing upon activation */
    $SINGLE_ITEM_SEGMENT_DURATION: 1.8; /* ex. time to fade into middle of area before fading out */
    $SINGLE_ITEM_TOTAL_DURATION : $SINGLE_ITEM_SEGMENT_DURATION * 2; /* fade in + pause + fade out */
    $OVERLAP_PREVIOUS_BY: 0.75; /* time to pull consecutive animated items forward in time to create carousel effect. Corresponds with 75% mark in animation keyframes below */

    @mixin delay($i) {
        @if $i == 1 {
            animation-delay: $GLOBAL_TIMELINE_DELAY;
            animation-duration: $SINGLE_ITEM_TOTAL_DURATION#{s};
        }

        @else if $i > 1 {
            animation-delay: ($GLOBAL_TIMELINE_DELAY + (($i - 1) * $SINGLE_ITEM_TOTAL_DURATION ) * $OVERLAP_PREVIOUS_BY);
            animation-duration: $SINGLE_ITEM_TOTAL_DURATION#{s};
        }
    }

    @for $i from 1 through list.length($categories) {
        .header-search-animated-category:nth-of-type(#{$i}) {
            @include delay($i);
        }
    }

    .header-search-animated-categories {
        position: absolute;
        display: block;
        top: 4px;
        bottom: 0;
        left: 17px;
        color: #565d65;
        height: $STORE_SEARCH_BAR_HEIGHT;
        pointer-events: none;

        &::before {
            content: 'Search by ';
            position: absolute;
            top: calc(50% + 0.5px);
            transform: translateY(-50%);
            line-height: 36px;
            width: max-content;
        }

        .header-search-animated-category {
            position: absolute;
            left: 71px;
            top: 2.5px;
            line-height: 36px;
            transform: translateY(100%);
            opacity: 0;
            height: $STORE_SEARCH_BAR_HEIGHT;
            width: max-content;
            animation-name: fade-in-out-vertically;
            animation-timing-function: cubic-bezier(0.11, 0.86, 0.68, 1);
            animation-fill-mode: forwards;

            &:first-of-type {
                animation-name: fade-out-vertically;
                transform: translateY(0%);
                opacity: 1;
            }

            &:last-of-type {
                animation-name: fade-in-vertically;
            }
        }
    }
}

@keyframes animated-search-fade-in {
    100% {
        opacity: 1;
    }
}

$NOMINAL_START_OR_END_POSITION : 55%;

@keyframes fade-in-out-vertically {
    0% {
        transform: translateY($NOMINAL_START_OR_END_POSITION);
        opacity: 0;
    }

    25% {
        transform: translateY(0%);
        opacity: 1;
    }

    75% {
        transform: translateY(0%);
        opacity: 1;
    }

    100% {
        transform: translateY(-$NOMINAL_START_OR_END_POSITION);
        opacity: 0;
    }
}

@keyframes fade-in-vertically {
    0% {
        transform: translateY($NOMINAL_START_OR_END_POSITION);
        opacity: 0;
    }

    25% {
        transform: translateY(0%);
        opacity: 1;
    }

    75% {
        transform: translateY(0%);
        opacity: 1;
    }

    /* end at zero percent, meaning center of input */

    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes fade-out-vertically {
    0% {
        transform: translateY(0%);
        opacity: 1;
    }

    25% {
        transform: translateY(0%);
        opacity: 1;
    }

    75% {
        transform: translateY(0%);
        opacity: 1;
    }

    /* end at negative percentage, meaning top of input */

    100% {
        transform: translateY(-$NOMINAL_START_OR_END_POSITION);
        opacity: 0;
    }
}
