@use 'sass-mq/mq' as *;
@use '@horizon/tokens/scss/partials/color' as *;
@use '@horizon/tokens/scss/partials/spacing' as *;
@use 'variables' as *;

#header {
    .header-drawer {
        display: flex;
        pointer-events: auto;

        .header-link {
            padding-right: $hzn-spacing-base;
            padding-left: $hzn-spacing-xsmall;

            &__delineated {
                border-top: 1px solid $hzn-color-border-neutral-weak;
                margin-top: 14px;
                padding-top: 27px !important;
            }
        }

        @include mq($until: $MOBILE_SIZE) {
            border-color: transparent;
            box-shadow: 0 8px 12px 0 rgba(42, 52, 61, 0.16);
            top: 0;
            left: 0;
            right: auto;
            visibility: hidden;
            position: fixed;
            flex-direction: column;
            transform: translate(-$MOBILE_NAV_DRAWER_WIDTH);
            width: $MOBILE_NAV_DRAWER_WIDTH;
            max-width: $MOBILE_NAV_DRAWER_WIDTH;
            height: 100%;
            z-index: $MOBILE_NAV_DRAWER_Z_INDEX;
            background-color: white;

            .header-drawer-body {
                display: block;
                overflow-y: scroll;

                .header-footer-icon-button {
                    padding: 12px 0 12px $hzn-spacing-base;
                    display: block;
                    line-height: normal;
                }

                .header-link {
                    padding: 12px 0 24px 4px;
                    display: block;
                    line-height: normal;
                    margin-right: 24px;
                }

                .header-link__careers-link {
                    padding: 20px 0 7px 4px !important;
                    margin-right: 34px;
                }
            }
        }

        &-container {
            margin: 0;
            padding: 0;
            height: 100vh;
            width: 100%;
            display: block;
            position: absolute;
            pointer-events: none;
            left: 0;

            @include mq($from: $MOBILE_SIZE) {
                height: auto;
                width: auto;
                position: relative;
            }
        }

        &-scrim {
            display: block;
            opacity: 0;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: $MOBILE_NAV_DRAWER_SCRIM_Z_INDEX;
            background-color: rgba(0, 0, 0, 0.32);

            &--visible {
                pointer-events: auto;
                opacity: 1;
                background-color: rgba(0, 0, 0, 0.4);
            }
        }

        &-header {
            height: $HEADER_NAV_HEIGHT_MOBILE;
            border-bottom: 1px solid $hzn-color-border-neutral-weak;
            display: none;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0 $hzn-spacing-base;
        }

        &-body {
            display: flex;
            align-items: center;

            @include mq($until: $MOBILE_SIZE) {
                #header-helpful-links-menu .header-footer-menu-divider {
                    display: none;
                }
            }
        }

        &.header-footer-menu--open {
            transform: translate(0, 0);
            visibility: visible;

            & + .header-drawer-scrim {
                pointer-events: auto;
                opacity: 1;
            }
        }
    }
}
