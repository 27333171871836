@use 'sass-mq/mq' as *;
@use '@horizon/tokens/scss/partials/color' as *;
@use '@horizon/tokens/scss/partials/spacing' as *;
@use '@horizon/tokens/scss/partials/fontWeight' as *;
@use '@horizon/tokens/scss/partials/fontFamily' as *;
@use 'variables' as *;
@use 'mixins' as *;
@use 'menu' as *;

@include enable-mobile-click-events();

body kmx-global-header {
    min-height: 112px;
    display: block;
    width: 100%;
}

@media screen and (min-width: 768px) {
    body kmx-global-header {
        min-height: 145px;
    }
}

// TODO: Remove this once search team takes out their override.
// Currently they are overriding the #header styles so we need to add a body tag to add more specificity, blocking their css.
body #header .header-nav-right #header-my-store-button #header-my-store-button-text,
body #header .header-nav-right #header-my-store-button.store-selection-test-menu-button #header-my-store-button-text {
    @include mq($from: $MOBILE_SIZE) {
        display: block !important;
    }
}

#header {
    // CSS reset, base styles, scoped to #header
    font-family: $hzn-fontfamily-body;
    background-color: $hzn-color-background;
    font-size: $BASE_FONT_SIZE;
    color: $hzn-color-text-neutral;
    position: relative;
    z-index: $HEADER_Z_INDEX;
    border-color: transparent;
    box-shadow: 0 4px 8px 0 rgba(42, 52, 61, 0.16);

    .mobile-hidden {
        @include mq($until: $MOBILE_SIZE) {
            display: none !important;
        }
    }

    .tablet-hidden {
        @include mq($from: $MOBILE_SIZE, $until: $TABLET_SIZE) {
            display: none !important;
        }
    }

    .desktop-hidden {
        @include mq($from: $TABLET_SIZE) {
            display: none !important;
        }
    }

    .hidden {
        display: none !important;
    }

    .header {
        &-link {
            text-decoration: none;
            color: inherit;
            padding: 8px 8px 8px 0;
            border: 2px solid transparent;

            &:focus {
                @include focus-styles($useBorderOutline: true);
            }
        }

        &-helpful-links-section {
            line-height: 24px;
        }

        &-nav {
            max-width: $CONTENT_MAX_WIDTH;
            height: $HEADER_NAV_HEIGHT_DESKTOP;
            margin: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background-color: $hzn-color-background;
            padding: 0 $hzn-spacing-medium 0;

            @include mq($until: $MOBILE_SIZE) {
                height: $HEADER_NAV_HEIGHT_MOBILE;
                padding: 0 $hzn-spacing-base 0;
            }

            @include mq($from: $CONTENT_MAX_WIDTH_WITH_PADDING) {
                padding: 0;
            }

            &-right {
                display: flex;
                align-items: center;

                @include mq($from: $MOBILE_SIZE) {
                    position: relative;
                }

                #header-saved-cars-button {
                    .header-footer-icon {
                        padding: $hzn-spacing-xsmall;
                        border-radius: 40px;
                    }

                    &.visible {
                        display: flex;
                    }

                    &:focus,
                    &.focus {
                        outline: none;

                        .header-footer-icon,
                        .header-footer-icon--priority {
                            box-shadow: inset 0 0 0 2px $hzn-color-border-brand-weak-dark;
                        }
                    }
                }

                .header-footer-menu-container {
                    top: 0;
                    left: 0;
                    right: 0;
                    position: relative;
                }

                .header-my-store-section,
                .header-my-profile-section {
                    display: flex;
                    padding: 0 1px;
                }

                .header-my-store-section {
                    .header-footer-icon {
                        background-color: transparent;
                    }
                }

                .header-my-store-section-no-store {
                    #header-my-store-menu {
                        margin-top: 42px;
                    }
                }

                #header-my-store-menu {
                    width: $STORE_SEARCH_MENU_WIDTH;
                    margin-top: 48px;
                }

                #header-my-profile-menu {
                    margin-top: 48px;

                    .header-footer-menu-list {
                        padding-top: $hzn-spacing-base;
                    }

                    .header-footer-menu-list-item {
                        &:hover {
                            background-color: $hzn-color-background-subdued;

                            &.payment-button {
                                background-color: #0000;
                            }
                        }
                    }

                    .header-link {
                        white-space: nowrap;

                        &:hover {
                            .icon-wrapper {
                                fill: $hzn-color-text-info;
                            }
                        }

                        &--favorites {
                            &:hover {
                                .icon-wrapper {
                                    fill: $hzn-color-text-flagged;
                                }
                            }
                        }
                    }

                    .icon-wrapper {
                        fill: $hzn-color-text-neutral;
                        height: 20px;
                        margin-right: $hzn-spacing-base;
                        width: 20px;
                    }

                    .payment-link {
                        @extend .header-footer-my-store-details-inventory-link !optional;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0 $hzn-spacing-base $hzn-spacing-base;
                        min-width: 240px;
                        height: 48px;
                        padding: $hzn-spacing-base;
                        white-space: nowrap;
                        color: $hzn-color-text-info;
                        font-family: $hzn-fontfamily-body;
                        font-weight: 700;
                        font-size: 14px;
                        border: 1px solid $hzn-color-tone-info;
                        border-radius: 8px;
                        text-decoration: none;
                        text-transform: uppercase;

                        &:hover {
                            background-color: $hzn-color-background-bright;
                        }
                    }
                }

                #header-my-store-menu,
                #header-my-profile-menu {
                    .header-footer-menu-list {
                        border-radius: $MENU_BORDER_RADIUS;
                    }

                    &.header-footer-menu--open {
                        @include header-footer-menu-open();
                    }

                    @include device-with-hover() {
                        &:hover {
                            @include header-footer-menu-open();
                        }
                    }
                }

                #header-my-profile-button {
                    position: relative;

                    .header-footer-icon {
                        padding: 5px;
                        border-radius: 40px;
                    }

                    &:focus,
                    &.focus {
                        outline: none;

                        .header-footer-icon,
                        .header-footer-icon--priority {
                            box-shadow: inset 0 0 0 2px $hzn-color-border-brand-weak-dark;
                        }
                    }

                    @include device-with-hover() {
                        &:hover {
                            outline: none;

                            & + .header-footer-menu-container > .header-footer-menu {
                                @include header-footer-menu-open();
                            }

                            &::before {
                                @include mq($until: 1200px) {
                                    @include header-footer-menu-hover-target(22px,0,30px,155px);
                                }

                                @include mq($from: 1200px) {
                                    @include header-footer-menu-hover-target(22px,0,30px,155px);
                                }
                            }
                        }
                    }
                }

                #header-my-store-button {
                    height: 40px;
                    border: 2px solid transparent;
                    padding-left: 0;
                    display: none;
                    position: relative;

                    &.visible { display: flex; }

                    .header-footer-icon {
                        padding-right: $hzn-spacing-xsmall;
                        padding-left: $hzn-spacing-xsmall;
                        border-radius: 40px;
                        display: block;
                    }

                    //TODO: Remove once search page _____ test has completed
                    .store-selection-test-header {
                        display: none !important;
                    }

                    & .header-footer-flyout-button-text-container {
                        display: none;

                        @include mq($from: 768px) {
                            display: flex;
                            text-align: left;
                            flex-direction: column;
                            padding: 2px 8px 0 0;
                        }
                    }

                    &.header-my-store-button-alert {
                        .header-alert-info-icon {
                            fill: $hzn-color-tone-critical;

                            &-container {
                                @include mq($from: $MOBILE_SIZE) {
                                    margin-right: $hzn-spacing-xsmall;
                                }
                            }
                        }

                        .header-footer-icon:first-of-type {
                            padding: 0;
                            border-radius: 0;
                            width: 40px;
                            height: 24px;

                            .header-footer-location-icon-alert-circle {
                                fill: $hzn-color-tone-critical;
                            }
                        }
                    }

                    &:focus {
                        @include focus-styles($useBorderOutline: true);
                    }

                    @include device-with-hover() {
                        &:hover {
                            & + .header-footer-menu-container > .header-footer-menu {
                                @include header-footer-menu-open();
                            }

                            &::before {
                                @include mq($until: 600px) {
                                    @include header-footer-menu-hover-target($height: 22px,$right: 0,$top:30px, $width: 155px);
                                }

                                @include mq($from: 600px) {
                                    @include header-footer-menu-hover-target($height: 22px,$right: 0,$top:30px, $width: 155px);
                                }
                            }
                        }
                    }
                }

                &--logged-in {
                    #header-my-store-button {
                        @include device-with-hover() {
                            &:hover {
                                &::before {
                                    @include mq($until: 1200px) {
                                        @include header-footer-menu-hover-target();
                                    }

                                    @include mq($from: 1200px) {
                                        @include header-footer-menu-hover-target($height: 22px,$right: 0,$top:30px, $width: 155px);
                                    }
                                }
                            }
                        }
                    }
                }

                #header-my-store-button + .header-footer-menu-container {
                    @include mq($until: 465px) {
                        position: absolute;
                        margin-left: $hzn-spacing-base;
                        margin-right: $hzn-spacing-base;

                        #header-my-store-menu {
                            width: 100%;
                            min-width: auto;
                            margin-top: $HEADER_NAV_HEIGHT_MOBILE;
                            margin-left: auto;
                            margin-right: auto;
                            left: 0;
                            right: 0;
                            max-width: none;
                        }
                    }

                    @include mq($from: 465px) {
                        position: relative;
                    }
                }

                .header-find-your-store-container {
                    display: flex;
                    fill: $hzn-color-text-neutral;

                    .header-footer-icon--secondary {
                        margin-top: -2px;
                    }
                }

                #header-my-store-button-zipCode {
                    text-align: left;
                    font-weight: $hzn-fontweight-base;
                    font-family: $hzn-fontfamily-body;
                    letter-spacing: normal;
                    font-size: 10px;
                    line-height: 12px;
                    color: $hzn-color-text-neutral;
                }

                #header-my-store-button-text {
                    font-size: 12px;
                    line-height: 14px;
                    color: $hzn-color-text-neutral;
                    font-weight: 700;
                }

                #header-my-store-button-text,
                #header-my-store-button-title,
                #header-my-store-button-zipCode,
                .header-footer-icon--secondary {
                    display: none;

                    @include mq($from: $MOBILE_SIZE) {
                        display: block !important;
                    }
                }
            }

            &-left {
                display: flex;
                align-items: center;

                #carmax-logo-header.header-link {
                    padding: 8px 0 0;
                }

                .header-main-logo {
                    height: 24px;
                    width: auto;

                    @media (min-width: 768px) {
                        height: 28px;
                    }
                }

                .header-link {
                    padding-right: 12px;
                    line-height: 24px;
                }

                #header-mobile-nav-button {
                    padding-right: $hzn-spacing-base;
                }

                #header-mobile-nav-button,
                #header-close-mobile-drawer-button {
                    &:focus {
                        @include focus-styles();
                    }
                }

                #header-helpful-links-menu,
                #header-shop-links-menu,
                #header-buy-links-menu,
                #header-sell-links-menu,
                #header-finance-links-menu {
                    .header-footer-menu-list {
                        @include mq($until: $MOBILE_SIZE) {
                            padding: 0 0 0 10px;
                            margin-bottom: 24px;
                        }

                        @include mq($from: $MOBILE_SIZE) {
                            border-radius: $MENU_BORDER_RADIUS;
                        }
                    }

                    &.header-footer-menu--open {
                        @include header-footer-menu-open();

                        @include mq($until: $MOBILE_SIZE) {
                            position: relative;
                            margin-top: 0;
                            box-shadow: none;
                            padding-left: $hzn-spacing-medium;
                        }
                    }

                    @include mq($from: $MOBILE_SIZE) {
                        margin-top: 0;

                        @include device-with-hover() {
                            &:hover {
                                @include header-footer-menu-open();
                            }
                        }
                    }
                }

                #header-shop-links-button,
                #header-buy-links-button,
                #header-sell-links-button,
                #header-finance-links-button {
                    @include mq($from: $MOBILE_SIZE) {
                        display: none;
                    }
                }

                #header-helpful-links-button,
                #header-shop-links-button,
                #header-buy-links-button,
                #header-sell-links-button,
                #header-finance-links-button {
                    height: 48px;
                    border: 2px solid transparent;
                    padding-left: 8px;
                    position: relative;
                    border-top: 0;

                    @include mq($until: $MOBILE_SIZE) {
                        border-top: 1px solid #f0f1f2;
                    }

                    .header-helpful-links {
                        &-title {
                            padding-right: 4px;

                            @include mq($until: $MOBILE_SIZE) {
                                font-weight: bold;
                                color: #2a343d;
                            }
                        }
                    }

                    .header-footer-icon-button-icon {
                        fill: $hzn-color-text-neutral;
                    }

                    &:focus {
                        @include focus-styles($useBorderOutline: true);
                    }

                    @include mq($until: $MOBILE_SIZE) {
                        width: 100%;
                        justify-content: space-between;
                        display: flex;
                        padding-right: $hzn-spacing-base;
                        padding-left: $hzn-spacing-base;

                        &.focus {
                            .header-helpful-links-button-icon {
                                transform: rotate(180deg);
                            }
                        }
                    }

                    @include mq($from: $MOBILE_SIZE) {
                        @include device-with-hover() {
                            &:hover {
                                & + .header-footer-menu-container > .header-footer-menu {
                                    @include header-footer-menu-open();
                                }

                                &::before {
                                    @include header-footer-menu-hover-target(22px,0,30px,72px);
                                }
                            }
                        }
                    }
                }
            }
        }

        &-search-bar-button-container {
            .header-footer-icon-button {
                background-color: #f5f6f7;
            }
        }
    }
}
