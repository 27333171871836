@use '@horizon/tokens/scss/partials/color' as *;

@mixin is-edge-browser() {
    @supports (-ms-ime-align: auto) {
        @content;
    }
}

@mixin focus-styles($outline-color: $hzn-color-border-brand-weak-dark, $useBorderOutline: false) {
    @if $useBorderOutline {
        outline: none;
        border: 2px solid $outline-color;
        border-radius: 8px 8px 8px 8px;
    } @else {
        outline-color: $outline-color;
        outline-style: auto;
        outline-width: initial;

        @include is-edge-browser() {
            outline-style: solid;
            outline-width: 1px;
        }
    }
}

@mixin device-with-hover {
    @media (hover: hover) and (pointer: fine) {
        @content;
    }
}

@mixin enable-mobile-click-events {
    body {
        // Fix mobile behavior around 'click' events
        cursor: pointer;

        @media (hover: hover) {
            cursor: auto;
        }
    }
}
