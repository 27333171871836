@use 'sass-mq/mq' as *;
@use 'variables' as *;

$hzn-fontfamily-body: Lato, Corbel, 'Lucida Grande', 'Lucida Sans Unicode', 'DejaVu Sans', 'Bitstream Vera Sans',
    'Liberation Sans', Verdana, 'Verdana Ref', sans-serif;
$hzn-fontfamily-display: CarMaxSharpSansDisp-Bold, Frutiger, 'Frutiger Linotype', Univers, Calibri, 'Gill Sans',
    'Gill Sans MT', 'Myriad Pro', Myriad, 'DejaVu Sans Condensed', 'Liberation Sans', 'Nimbus Sans L', Tahoma, Geneva,
    'Helvetica Neue', Helvetica, Arial, sans-serif;
$menu-backdrop-transition-length: 0.25s;
$menu-backdrop-easing-function: ease-in;
$hzn-color-text-neutral: #2a343d;
$hzn-color-text-neutral-on-brand: #fff;
$hzn-color-tone-interactive: #005ab8;
$hzn-color-background: #fff;
$hzn-color-background-dark: #051b34;
$hzn-color-background-subdued: #f5f6f7;
$hzn-color-border-neutral-weak: #dedfe0;
$height-of-bar: 64px;
$intro-animation-margin: 30px;

kmx-thumb-nav {
    @include mq($from: $MOBILE_SIZE + 1) {
        display: none;
    }
}

kmx-thumb-bar {
    .kmx-hf__thumb-bar {
        position: fixed;
        bottom: 0;
        padding-bottom: env(safe-area-inset-bottom);
        width: 100%;
        z-index: 1002;
        color: #fff;
        height: calc(#{$height-of-bar} + env(safe-area-inset-bottom));
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 0 !important;
        background: $hzn-color-background;
        border-top: 1px solid rgba(0, 0, 0, 0);
        box-shadow: 0 -4px 32px 0 rgba(0, 0, 0, 0.16);
        box-sizing: border-box;

        &--open {
            border-top: 1px solid $hzn-color-border-neutral-weak;
            box-shadow: 0 -4px 32px 0 rgba(0, 0, 0, 0);
        }
    }
}

kmx-thumb-bar-item {
    flex-basis: 20%;
    margin-bottom: 4px;
    min-width: 48px;

    &:first-of-type {
        margin-left: 8px;
    }

    &:last-of-type {
        margin-right: 8px;
    }

    .kmx-hf__thumb-bar__item {
        $this: &;

        list-style-type: none;
        display: flex;
        align-items: center;
        margin: 0;
        line-height: normal;

        &__icon {
            height: 24px;
            width: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $hzn-color-text-neutral;

            path {
                fill: $hzn-color-text-neutral;
            }
        }

        &__title {
            color: $hzn-color-text-neutral;
            font-size: 10px;
            font-family: $hzn-fontfamily-body;
            margin-top: 3px;
            font-weight: 400;
            white-space: nowrap;
        }

        &__seoSubMenu {
            position: absolute;
            left: -9999px;
            width: 1px;
            height: 1px;
            visibility: hidden;
        }

        &__button {
            width: 100%;
            background: transparent;
            border: none;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: flex-end;
            height: 45px;
            font-family: $hzn-fontfamily-body;
            line-height: normal;

            &--highlighted {
                .kmx-hf__thumb-bar__item__title {
                    color: $hzn-color-text-neutral;
                }

                .kmx-hf__thumb-bar__item__icon {
                    height: 24px;
                    width: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $hzn-color-text-neutral;
                }

                &.kmx-hf__thumb-bar__item--active {
                    .kmx-hf__thumb-bar__item__title {
                        font-weight: 700;
                        color: $hzn-color-tone-interactive;
                    }

                    .kmx-hf__thumb-bar__item__icon {
                        color: $hzn-color-tone-interactive;

                        path {
                            fill: $hzn-color-tone-interactive;
                        }
                    }
                }
            }
        }
    }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .kmx-hf__thumb-bar {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);

        &.bg-glass--gray {
            background: rgba(245, 246, 247, 0.6);
            -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
            box-shadow: 0 -4px 32px 0 rgba(0, 0, 0, 0);

            &.kmx-hf__thumb-bar--open {
                background: $hzn-color-background;
            }
        }
    }
}

kmx-thumb-nav-menu {
    .kmx-hf__menu {
        transition: all 200ms ease-in;
        position: fixed;
        bottom: $height-of-bar;
        z-index: 1001;
        width: 100%;
        height: 0;
        overflow: hidden;
        background: $hzn-color-background;
        border-radius: 16px 16px 0 0;

        &.menu--fade-in {
            transition: all 250ms ease-out;
        }

        &.menu--fade-out {
            opacity: 0;
        }

        #kmx-thumb-nav-menu-content {
            height: 100%;
            overflow: auto;
        }

        &__title {
            font-family: $hzn-fontfamily-display;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: 0.38px;
            margin: 30px 0 32px 32px;
            display: block;
            color: $hzn-color-text-neutral;
        }

        &__list {
            padding: 0;
            margin: 0 32px 0;
        }

        &__list#More {
            > kmx-thumb-nav-menu-item {
                &:nth-of-type(4) {
                    .kmx-hf__menu__list__item {
                        margin-top: 16px;
                    }
                }
            }
        }

        &__btn-close {
            position: absolute;
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            right: 0;
            top: 0;
            background: none;
            border: none;
            cursor: pointer;
            color: $hzn-color-text-neutral;
        }

        &__backdrop {
            content: ' ';
            position: fixed;
            opacity: 0;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.4);
            transition: opacity $menu-backdrop-transition-length $menu-backdrop-easing-function;
            z-index: 1000;

            &.mobile-backdrop--visible {
                opacity: 1;
            }
        }

        &.signed-in {
            #MyAccount.kmx-hf__menu__list,
            #Mydashboard.kmx-hf__menu__list {
                display: flex;
                flex-wrap: wrap;
                gap: 18px;
                row-gap: 0;

                #my-favorites,
                #favorites {
                    margin-top: 76px;
                }

                > kmx-thumb-nav-menu-item {

                    /* styles first 3 MyAccount menu items  */

                    &:nth-of-type(-n + 3) {
                        width: calc(100% / 3 - 13px);
                        margin: 0;
                        aspect-ratio: 1/1;

                        .kmx-hf__menu__list__item {
                            &__link {
                                width: 100%;
                                text-align: center;
                                display: block;

                                &__text {
                                    font-size: 12px;
                                    display: block;
                                    margin-top: 8px;
                                    white-space: nowrap;
                                }

                                &__icon {
                                    aspect-ratio: 1/1;
                                    width: 100%;
                                    height: 100%;
                                    margin-right: 0;
                                }
                            }
                        }
                    }

                    /* Styles the 4th item under the Account ( logged in menu ) */

                    &:nth-of-type(4) {
                        .kmx-hf__menu__list__item {
                            margin-top: 72px;
                        }
                    }

                    /* styles rest of MyAccount menu items */
                    &:nth-of-type(n + 4):nth-of-type(-n + 99) {
                        flex-basis: 100%;
                    }

                    #sign-out-link {
                        color: #b30000 !important;
                        margin-top: 16px;
                    }
                }
            }
        }
    }

    .mydashboard-loader {
        width: 24px;
        height: 24px;
        border: 2px solid $hzn-color-text-neutral;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: mydashboard-loader-ring 1s linear infinite;
    }

    @keyframes mydashboard-loader-ring {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    kmx-thumb-nav-menu-item {
        width: 100%;

        .kmx-hf__menu__list__item {
            color: $hzn-color-text-neutral;
            font-family: $hzn-fontfamily-body;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;

            &.has-icon {
                margin-bottom: 8px;
            }

            &__link {
                font-family: $hzn-fontfamily-body;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                text-decoration: none;
                min-height: 48px;
                max-height: 72px;
                color: $hzn-color-text-neutral !important;
                display: flex;
                align-items: center;

                &__icon {
                    height: 72px;
                    width: 72px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 16px;
                    background: $hzn-color-background-subdued;
                    border-radius: 16px;

                    path {
                        fill: $hzn-color-text-neutral;
                    }
                }
            }
        }

        .vehicle_hero-img {
            border-radius: 16px;
            height: 100%;
            width: 100%;
        }
    }
}

@media screen and (max-width: 900px) {
    #kmx-hf__menu__btn-close {
        display: block;
    }

    // CROSS APP OVERRIDES for competing elements Brandscape does not own

    #instant-cash-offer {
        z-index: 1 !important;
    }

    #wl-snackbar-desc,
    #parking-lot-snackbar,
    .tile-snackbar,
    .hero-featured-content--info-dialog.mdc-dialog {
        z-index: 1010 !important;
    }

    // raise up green toast on MyKmx Favorites and Comparison success
    .kmx-toast {
        z-index: 1003;
    }

    .fullScreenDialog {
        z-index: 1002;
    }

    /* note : manageChatFab function also manages position on car detail page when gallery is open */
    /* Manages position when chatbox opens and closes */

    #startChatFab,
    #skyeChatBot:not(.chatbox-active) + div #embeddedChatFab {
        transform: translateY(-70px);
        transition: transform 0.25s ease-out;
    }

    #SkyeChat hzn-icon-button {
        bottom: calc(5rem + env(safe-area-inset-bottom));
    }

    /* compare page footer bar that holds Continue button  */

    .select-cars__content {
        padding-bottom: 175px !important;
    }

    .select-cars__footer {
        bottom: $height-of-bar !important;
    }
}

body {
    padding-bottom: $height-of-bar !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 768px) {
    body {
        padding-bottom: 0 !important;
    }
}
