@use 'normalize';
@use 'settings' as *;
@use 'mixins';
@use 'utilities';
@use 'skip-nav';
@use 'icon-button';
@use 'alert-banner';
@use 'menu';
@use 'mobile-nav';
@use 'search-bar';
@use 'drawer';
@use 'my-store-flyout';
@use 'header';
@use 'header-transparent';
@use 'footer';
@use 'store-search';


@font-face {
    font-display: swap;
    font-family: 'CarMaxSharpSansDisp-Bold';
    font-style: normal;
    font-weight: 700;
    src: url('#{$webAssetsServiceProtocol}://#{$webAssetsServiceHost}/shared/fonts/CarMaxSharpSansDisp-Bold.woff2')
            format('woff2'),
        url('#{$webAssetsServiceProtocol}://#{$webAssetsServiceHost}/shared/fonts/CarMaxSharpSansDisp-Bold.woff')
            format('woff'),
        url('#{$webAssetsServiceProtocol}://#{$webAssetsServiceHost}/shared/fonts/CarMaxSharpSansDisp-Bold.ttf')
            format('truetype');
}

/* lato-regular - latin */
@font-face {
    font-display: swap;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('#{$webAssetsServiceProtocol}://#{$webAssetsServiceHost}/shared/fonts/lato-v16-latin-regular.woff2')
            format('woff2'),
        url('#{$webAssetsServiceProtocol}://#{$webAssetsServiceHost}/shared/fonts/lato-v16-latin-regular.woff')
            format('woff'),
        url('#{$webAssetsServiceProtocol}://#{$webAssetsServiceHost}/shared/fonts/lato-v16-latin-regular.ttf')
            format('truetype');
}

/* lato-700 - latin */
@font-face {
    font-display: swap;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url('#{$webAssetsServiceProtocol}://#{$webAssetsServiceHost}/shared/fonts/lato-v16-latin-700.woff2')
            format('woff2'),
        url('#{$webAssetsServiceProtocol}://#{$webAssetsServiceHost}/shared/fonts/lato-v16-latin-700.woff')
            format('woff'),
        url('#{$webAssetsServiceProtocol}://#{$webAssetsServiceHost}/shared/fonts/lato-v16-latin-700.ttf')
            format('truetype');
}

.body-scroll-lock {
    overflow: hidden;
    min-height: 100vh;
}
