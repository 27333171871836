@use '@horizon/tokens/scss/partials/color' as *;
@use '@horizon/tokens/scss/partials/spacing' as *;

.skip-nav {
    align-items: center;
    background-color: #fff;
    color: $hzn-color-text-info;
    border: 2px solid $hzn-color-tone-info;
    border-radius: 8px 8px 8px 8px;
    display: flex;
    font-weight: bold;
    font-size: 14px;
    height: 32px;
    line-height: 20px;
    letter-spacing: 0.5px;
    justify-content: center;
    padding: $hzn-spacing-xsmall;
    position: relative;
    margin-top: -32px;
    outline: none;
    text-decoration: none;

    &:focus {
        margin: 3px;
    }
}
