@use '@horizon/tokens/scss/partials/color' as *;
@use 'sass-mq/mq' as *;
@use 'variables' as *;
@use 'mixins' as *;

$TRANSPARENT_ICON_TOGGLE_HOVER_FOCUS_BACKGROUND_COLOR: rgba(255, 255, 255, 0.08);
$TRANSPARENT_THEME_INPUT_BACKGROUND_COLOR: #F5F6F7;

#header.transparent-dark {
    background-color: transparent;
    box-shadow: none;

    .header-nav,
    .header-search-bar-form,
    .header-footer-icon-button:not(#header-store-search-button):not(#header-search-button) {
        background-color: transparent;
    }

    .header-search-bar-input,
    #header-search-button {
        background-color: $TRANSPARENT_THEME_INPUT_BACKGROUND_COLOR;
    }

    .header-link,
    #header-helpful-links-button,
    #header-my-store-button {
        &:focus {
            @include focus-styles($useBorderOutline: true);
        }
    }

    #header-saved-cars-button,
    #header-my-profile-button {
        &:focus,
        &.focus {
            outline: none;

            .header-footer-icon,
            .header-footer-icon--priority {
                box-shadow: inset 0 0 0 2px $hzn-color-border-brand-weak-dark;
            }
        }
    }

    #header-inventory-search {
        &:hover,
        &:focus {
            & + .header-search-bar-button-container > .header-footer-icon-button {
                background-color: $DEFAULT_SEARCH_BAR_OUTLINE_COLOR;
                box-shadow: $DEFAULT_SEARCH_BAR_BUTTON_OUTLINE;
                border-left: none;
            }

            background-color: $DEFAULT_SEARCH_BAR_OUTLINE_COLOR;
            box-shadow: $DEFAULT_SEARCH_BAR_OUTLINE;
            border-right: none;
        }
    }
}

#header.transparent-light {
    background-color: transparent;
    box-shadow: none;

    .header-link,
    #header-helpful-links-button,
    #header-my-store-button {
        &:focus {
            @include focus-styles(white, true);
        }

        color: $hzn-color-text-inverse;
    }

    #header-inventory-search {
        &:hover,
        &:focus {
            & + .header-search-bar-button-container > .header-footer-icon-button {
                background-color: white;
                box-shadow: 1px -1px 0 1px white, 1px 1px 0 1px white, inset -1px 1px 0 0 $hzn-color-tone-info,
                    inset -1px -1px 0 0 $hzn-color-tone-info;
            }

            background-color: white;
            box-shadow: 0px 0px 0 2px white, inset 1px -1px 0 0 $hzn-color-tone-info,
                inset 1px 1px 0 0 $hzn-color-tone-info;
        }
    }

    .header-nav,
    .header-search-bar-form,
    .header-footer-icon-button:not(#header-store-search-button):not(#header-search-button) {
        background-color: transparent;
    }

    .header-search-bar-input,
    #header-search-button {
        background-color: $TRANSPARENT_THEME_INPUT_BACKGROUND_COLOR;
    }

    .header-nav-right #header-my-store-button,
    .header-nav-right #header-my-store-button #header-my-store-button-zipCode,
    #header-my-store-button-text {
        color: $hzn-color-text-inverse;
    }

    .header-nav-right #header-my-store-button .header-footer-icon.tablet-hidden.desktop-hidden path:nth-child(1),
    .header-main-logo path,
    .header-footer-icon--secondary path:first-child,
    #header-my-profile-button .header-footer-icon path:first-child,
    #header-mobile-nav-button path:nth-child(2),
    #header-saved-cars-button .header-footer-icon path,
    #header-my-store-button .header-footer-icon {
        fill: $hzn-color-background;
    }

    .header-footer-icon-button-icon {
        fill: $hzn-color-background !important;
    }

    #header-saved-cars-button,
    #header-my-profile-button {
        &:focus,
        &.focus {
            outline: none;

            .header-footer-icon,
            .header-footer-icon--priority {
                box-shadow: 0 0 0 2px white;
            }
        }

        &:hover {
            .header-footer-icon {
                background: $TRANSPARENT_ICON_TOGGLE_HOVER_FOCUS_BACKGROUND_COLOR;
            }
        }
    }

    @include mq($until: 600px) {
        #header-my-store-button {
            &:hover,
            &:focus {
                .header-footer-icon {
                    background: $TRANSPARENT_ICON_TOGGLE_HOVER_FOCUS_BACKGROUND_COLOR;
                }
            }
        }
    }

    @include mq($from: 900px) {
        #header-helpful-links-button svg path:first-child {
            fill: $hzn-color-background;
        }

        .header-mobile-drawer-container {
            color: $hzn-color-text-inverse;
        }
    }


    .header-search-bar{
        .header-footer-menu-container{
            .header-footer-menu{
                box-shadow: -1px 2px 0 1px white, 1px 1px 0 1px white;
            }
        }
    }
}
