@use '@horizon/tokens/scss/partials/color' as *;
@use 'mixins' as *;

$ICON_TOGGLE_FOCUS_BACKGROUND_COLOR: rgba(23, 115, 207, 0.24);
$ICON_TOGGLE_HOVER_BACKGROUND_COLOR: rgba(23, 115, 207, 0.08);
$PRIORITY_ICON_TOGGLE_FOCUS_BACKGROUND_COLOR: rgba(219, 24, 122, 0.24);
$PRIORITY_ICON_TOGGLE_HOVER_BACKGROUND_COLOR: rgba(23, 115, 207, 0.08);

#header,
#footer {
    .header-footer-icon-button {
        outline: none;
        border: none;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        background-color: $hzn-color-background;
        margin: 0;
        padding: 0;

        .header-footer-icon {
            border-radius: 32px;
            padding: 4px;
            fill: $hzn-color-text-neutral;
        }

        @include device-with-hover() {
            &:hover {
                .header-footer-icon {
                    background-color: $ICON_TOGGLE_HOVER_BACKGROUND_COLOR;
                }

                .header-footer-icon--priority {
                    background-color: $PRIORITY_ICON_TOGGLE_HOVER_BACKGROUND_COLOR;
                }
            }
        }
    }
}
