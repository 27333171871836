#header,
#footer {
    font-size: 16px;

    * {
        box-sizing: border-box;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
}

#skipMainContent {
    box-sizing: border-box;
    text-transform: uppercase;
}
