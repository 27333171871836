@use 'sass-mq/mq' as *;
@use '@horizon/tokens/scss/partials/color' as *;
@use '@horizon/tokens/scss/partials/fontFamily' as *;
@use '@horizon/tokens/scss/partials/lineHeight' as *;
@use 'variables' as *;

$INPUT_BORDER_COLOR: $hzn-color-border-neutral-weak;
$INPUT_ACTIVE_BORDER_COLOR: $hzn-color-border-brand-weak-dark;
$INPUT_BACKGROUND_COLOR: $hzn-color-border-neutral-weak;

#header,
#footer {
    .store-chooser-wrapper {
        display: none;

        &.visible {
            display: flex;
        }
    }

    .store-chooser-background-modal {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9998;
    }

    .store-chooser-modal {
        height: 90vh;
        max-height: 650px;
        width: 90vw;
        max-width: 650px;
        box-shadow: 3px 3px 20px 1px $hzn-color-border-neutral;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        border-radius: 16px;
        padding: 24px;
        z-index: 9999;
        overflow: hidden;

        &-body {
            padding-top: 12px;
            border-top: 1px solid $hzn-color-border-neutral-weak;
        }

        &_header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &-title {
                font-weight: 700;
                font-family: $hzn-fontfamily-display;
                margin: 0;
                font-size: 24px;
            }

            &-close {
                border: none;
                background: none;
                cursor: pointer;
                margin-right: -16px;

                &-icon {
                    padding: 4px;

                    &-svg {
                        height: 24px;
                        width: 24px;
                        fill: $hzn-color-tone-brand;
                    }
                }
            }
        }

        &_search-form {
            display: flex;
            margin: 16px auto 12px;
            border-radius: 8px;
            flex-direction: row;
            justify-content: flex-start;

            &-input {
                width: calc(100% - 32px);
                height: 40px;
                border: 1px solid $hzn-color-tone-subdued;
                border-right-width: 0;
                padding: 0 0 0 12px;
                border-radius: 8px 0 0 8px;
                background: $hzn-color-tone-neutral-weak;
                transition: background-color 0.1s, border-color 0.1s;
                margin-top: 0;

                &:hover,
                &:active,
                &:focus {
                    & + .store-chooser-modal_search-form-button {
                        border: 1px solid $hzn-color-tone-info;
                        background-color: $DEFAULT_SEARCH_BAR_OUTLINE_COLOR;
                        box-shadow: $DEFAULT_SEARCH_BAR_BUTTON_OUTLINE;
                        border-left: none;
                    }

                    border: 1px solid $hzn-color-tone-info;
                    background-color: $DEFAULT_SEARCH_BAR_OUTLINE_COLOR;
                    box-shadow: $DEFAULT_SEARCH_BAR_OUTLINE;
                    border-right: none;
                }

                &:focus-visible {
                    outline: none;
                }
            }

            &-button {
                background: $hzn-color-tone-neutral-weak;
                border-radius: 0 8px 8px 0;
                border: 1px solid $hzn-color-tone-subdued;
                border-left-width: 0;
                padding: 0 8px;
                cursor: pointer;
                margin: 0;

                &-svg {
                    width: 24px;
                    height: 24px;
                    fill: $hzn-color-tone-brand;
                }
            }
        }

        &_error {
            display: flex;
            margin: auto;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-svg {
                width: 64px;
                height: 64px;
                fill: $hzn-color-text-info;
            }

            &-text {
                text-align: center;
                color: $hzn-color-text-neutral;
            }
        }

        &_list {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            max-height: calc(650px - 160px);
            padding-right: 16px;
            overflow-y: auto;
            overflow-x: hidden;
            list-style-type: none;

            &::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 4px;
            }

            &::-webkit-scrollbar-track {
                background: $hzn-color-tone-neutral-weak;
                border-radius: 16px;
            }

            &::-webkit-scrollbar-thumb {
                background: $hzn-color-text-subdued;
                border-radius: 16px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: $hzn-color-background;
            }

            &-store {
                width: 100%;
                border: 1px solid $hzn-color-border-neutral-weak;
                border-radius: 8px;
                margin: 8px auto;
                padding: 16px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                @include mq($until: 600px) {
                    flex-direction: column;
                    justify-content: flex-start;
                }

                &-info {
                    width: calc(100% - 225px);

                    @include mq($until: 600px) {
                        width: 100%;
                        margin-bottom: 8px;
                    }

                    & .store-title {
                        display: flex;
                        justify-content: flex-start;
                        flex-direction: row;
                        align-items: baseline;

                        &-name {
                            margin: 0 4px 0 0;
                        }

                        &-distance {
                            color: $hzn-color-text-input-placeholder;
                        }
                    }

                    & .store-address {
                        margin-top: 8px;
                        margin-bottom: 0;
                        color: $hzn-color-text-subdued;
                        line-height: 20px;
                    }

                    & .store-hours {
                        margin-top: 8px;
                        margin-bottom: 0;
                        color: $hzn-color-text-input-placeholder;
                        font-size: 14px;
                        line-height: 20px;
                        display: flex;

                        &.alert {
                            color: $hzn-color-text-critical;

                            & .warningIcon {
                                height: 20px;
                                width: 20px;
                                margin-right: 8px;
                                fill: $hzn-color-tone-critical;
                            }
                        }
                    }
                }

                &-select {
                    width: 225px;

                    &-button {
                        border: 1px solid $hzn-color-tone-info;
                        background: none;
                        color: $hzn-color-text-info;
                        display: flex;
                        width: 100%;
                        line-height: 48px;
                        height: 48px;
                        border-radius: 8px;
                        padding: 0;
                        text-align: center;
                        font-weight: bold;
                        font-size: 14px;
                        text-transform: uppercase;
                        justify-content: center;
                        cursor: pointer;
                        transition: background-color 0.375s ease, color 0.125s ease;

                        &:hover {
                            background: $hzn-color-tone-info;
                            color: white;
                        }
                    }

                    &-selected,
                    &-grand-open {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 14px;

                        & .selected-store-svg,
                        & .grand-open-store-svg {
                            width: 24px;
                            height: 24px;
                            margin-right: 4px;
                        }
                    }

                    &-selected {
                        color: $hzn-color-text-positive;

                        .selected-store-svg {
                            fill: $hzn-color-tone-positive;
                            margin-left: -8px;
                        }
                    }

                    &-grand-open {
                        color: $hzn-color-text-flagged;

                        .grand-open-store-svg {
                            fill: $hzn-color-text-flagged;
                            margin-left: -24px;
                        }
                    }
                }

                &-all-link {
                    margin: 16px auto;
                    padding: 16px;
                    text-align: center;
                    font-weight: bold;
                    font-size: 14px;
                    display: flex;
                    color: $hzn-color-text-info;
                    text-decoration: none;
                    text-transform: uppercase;
                }
            }
        }

        &_no-matches-text {
            font-family: $hzn-fontfamily-body;
            line-height: $hzn-lineheight-base;
            font-size: 14px;
            color: #000;
        }
    }

    .carmax-loading {
        display: flex;
        position: relative;
        width: 80px;
        height: 80px;
        margin: 80px auto;

        &-ring {
            box-sizing: border-box;
            display: flex;
            position: absolute;
            width: 72px;
            height: 72px;
            border: 8px solid $hzn-color-tone-info;
            border-radius: 50%;
            animation: carmax-loading-ring 1s cubic-bezier(0.5, 0.5, 0.5, 1) infinite;
            border-color: $hzn-color-text-info transparent transparent transparent;

            &.second-ring {
                animation-delay: -0.45s;
            }

            &.third-ring {
                animation-delay: -0.3s;
            }

            &.fourth-ring {
                animation-delay: -0.15s;
            }
        }
    }

    @keyframes carmax-loading-ring {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}
