@use '@horizon/tokens/scss/partials/color' as *;
@use '@horizon/tokens/scss/partials/fontFamily' as *;
@use '@horizon/tokens/scss/partials/fontWeight' as *;
@use '@horizon/tokens/scss/partials/lineHeight' as *;
@use 'sass-mq/mq' as *;
@use 'variables';

#alert-banner {
    position: relative;
    background-color: $hzn-color-background-bright-dark;
    padding: 8px 16px;

    &.tall {
        padding: 12px 16px;
    }

    @include mq($from: 900px) {
        padding-left: 32px;
        padding-right: 32px;
    }

    .alert-banner {
        &-container {
            max-width: 1200px;
            margin: auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        &-details {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }

        &-message {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            a {
                color: #fff;
            }
        }

        &-description {
            font-weight: $hzn-fontweight-base;
            font-family: $hzn-fontfamily-body;
            letter-spacing: normal;
            font-size: 0.875rem; // $hzn-fontsize-body-small
            line-height: 1.42857;
            color: $hzn-color-text-inverse;
            margin: 0;
        }

        &-icon {
            align-self: flex-start;
            min-width: 24px;
            min-height: 24px;
            margin-right: 16px;

            @include mq($from: 600px) {
                align-self: center;
            }
        }

        &-close-button {
            width: 24px;
            height: 24px;
            align-self: flex-start;
            outline: none;
            border: none;
            cursor: pointer;
            font-family: inherit;
            font-size: inherit;
            color: inherit;
            background-color: transparent;
            margin: 0 0 0 16px;
            padding: 0;

            &:focus {
                outline-color: white;
                outline-style: auto;
                outline-width: medium;
                outline-width: initial;
            }

            @include mq($from: 600px) {
                align-self: center;
            }
        }

        &-close-icon {
            min-width: 24px;
            min-height: 24px;
        }
    }

    &.urgent {
        background-color: $hzn-color-tone-critical-dark;

        .alert-banner-description {
            font-weight: 700;
        }
    }

    &.dark {
        background-color: $hzn-color-tone-neutral;
    }
}
