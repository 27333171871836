@use '@horizon/tokens/scss/partials/color' as *;

$XSMALL_FONT_SIZE: 12px;
$SMALL_FONT_SIZE: 14px;
$BASE_FONT_SIZE: 16px;
$LARGE_FONT_SIZE: 20px;
$HEADER_NAV_HEIGHT_DESKTOP: 72px;
$HEADER_NAV_HEIGHT_MOBILE: 48px;
$SEARCH_BAR_HEIGHT: 48px;
$STORE_SEARCH_BAR_HEIGHT: 40px;
$FOOTER_NAV_HEIGHT_DESKTOP: 80px;
$FOOTER_NAV_HEIGHT_MOBILE: 48px;
$BUTTON_HEIGHT: 48px;

$MENU_BORDER_RADIUS: 8px;
$INPUT_BORDER_RADIUS: 8px;

$CONTENT_MAX_WIDTH: 1200px;
$CONTENT_MAX_WIDTH_WITH_PADDING: 1264px;
$MOBILE_SIZE: 767px;
$TABLET_SIZE: 1200px;

$MOBILE_NAV_Z_INDEX: 10;
$MOBILE_NAV_DRAWER_WIDTH: 280px;
$MENU_MIN_WIDTH: 155px;
$MENU_MAX_WIDTH: 336px;
$STORE_SEARCH_MENU_WIDTH: 300px;

$HEADER_Z_INDEX: 740;
$SECONDARY_MENU_Z_INDEX: 750;
$PRIMARY_MENU_Z_INDEX: 760;
$MOBILE_NAV_SUBMENU_TEST_HEIGHT: 100%;
$MENU_HOVER_TARGET_Z_INDEX: 761;
$MOBILE_NAV_DRAWER_SCRIM_Z_INDEX: 762;
$MOBILE_NAV_DRAWER_Z_INDEX: 763;

$DEFAULT_SEARCH_BAR_OUTLINE: inset 0 1px 0 0 $hzn-color-tone-info, inset 1px -1px 0 0 $hzn-color-tone-info;
$DEFAULT_SEARCH_BAR_DROP_DOWN_OUTLINE: -1px 2px 0 1px white, 1px 1px 0 1px white;
$DEFAULT_SEARCH_BAR_BUTTON_OUTLINE: inset 0 1px 0 0 $hzn-color-tone-info, inset -1px -1px 0 0 $hzn-color-tone-info;
$DEFAULT_SEARCH_BAR_OUTLINE_COLOR: white;
