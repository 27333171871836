@use '@horizon/tokens/scss/partials/color' as *;
@use '@horizon/tokens/scss/partials/spacing' as *;
@use '@horizon/tokens/scss/partials/fontFamily' as *;
@use '@horizon/tokens/scss/partials/fontWeight' as *;
@use '@horizon/tokens/scss/partials/lineHeight' as *;
@use 'variables' as *;

#header,
#footer {
    .header-footer-my-store-details {
        background-color: $hzn-color-background;
        padding: $hzn-spacing-base;
        color: $hzn-color-text-neutral;
        line-height: $hzn-spacing-medium;
        border-top-right-radius: $MENU_BORDER_RADIUS;
        border-top-left-radius: $MENU_BORDER_RADIUS;

        &-phone-link,
        &-link,
        &-inventory-link {
            outline-color: $hzn-color-text-info;
        }

        &-link,
        &-inventory-link {
            color: $hzn-color-text-info;
            font-size: $SMALL_FONT_SIZE;
        }

        &-hours-list-item {
            color: $hzn-color-text-input-placeholder;
            font-size: $SMALL_FONT_SIZE;
        }

        &-header,
        &-hours-header {
            font-size: $XSMALL_FONT_SIZE;
            color: $hzn-color-text-input-placeholder;
            line-height: 1;
            margin-bottom: $hzn-spacing-xxsmall;
        }

        &-store-name {
            font-family: $hzn-fontfamily-display;
            font-size: $LARGE_FONT_SIZE;
            color: $hzn-color-text-brand;
            font-weight: 700;
            letter-spacing: 0.25px;
        }

        &-distance {
            font-weight: $hzn-fontweight-base;
            font-family: $hzn-fontfamily-body;
            letter-spacing: normal;
            font-size: 0.875rem; // $hzn-fontsize-body-small
            line-height: 1.42857;
            margin-left: $hzn-spacing-xxsmall;
            color: $hzn-color-text-input-placeholder;
        }

        &-hours-list-item,
        &-link {
            display: block;
        }

        &-hours {
            line-height: 20px;
        }

        &-link {
            margin-top: $hzn-spacing-xsmall;
            display: inline-block;
        }

        &-phone-link {
            color: $hzn-color-text-info-on-brand-accent;
            display: block;
            font-size: $BASE_FONT_SIZE;
            text-decoration: none;
        }

        &-address {
            font-size: $BASE_FONT_SIZE;
            line-height: 20px;
            margin: $hzn-spacing-xxsmall 0 $hzn-spacing-xsmall;
            color: $hzn-color-text-input-placeholder;
        }

        &-inventory-link {
            height: $BUTTON_HEIGHT;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $hzn-color-text-info;
            border-radius: $INPUT_BORDER_RADIUS;
            margin: $hzn-spacing-medium auto 0;
            text-decoration: none;
            text-transform: uppercase;
            font-weight: 700;

            &:hover {
                outline: 3px solid transparent;
                box-shadow: 0 0 0 2px #4094e3;
            }
        }

        &-info-section {
            font-size: $BASE_FONT_SIZE;
        }

        &-store-hours-button {
            display: flex;
            align-items: center;
            position: relative;
            background: none;
            border: 0;
            cursor: pointer;
            width: 100%;
            outline-color: $hzn-color-text-info;
            padding: 0;
            margin-top: $hzn-spacing-xsmall;
            font-weight: $hzn-fontweight-base;
            font-family: $hzn-fontfamily-body;
            letter-spacing: normal;
            font-size: 0.875rem; // $hzn-fontsize-body-small
            line-height: 1.42857;

            .icon-wrapper {
                width: 20px;
                height: 20px;
                margin-right: 2px;
                fill: currentColor;

                .warningIcon {
                    display: block;
                    width: 100%;
                    fill: $hzn-color-tone-critical;
                }
            }

            .store-status-text {
                margin-right: $hzn-spacing-xsmall;

                &.open {
                    color: $hzn-color-text-positive;
                }

                &.closed {
                    color: $hzn-color-text-critical;
                }

                &.holiday,
                &.alert-closed {
                    color: $hzn-color-text-critical;
                    font-weight: bold;
                }

                &.alert-closed {
                    margin-right: 0;
                }
            }

            .store-status-hours {
                color: $hzn-color-text-input-placeholder;
            }

            &.visible {
                .dropdownIcon {
                    transform: rotate(180deg);
                }
            }
        }

        &-store-hours-menu {
            display: none;
            padding: 0 0 $hzn-spacing-xxsmall $hzn-spacing-xsmall;
            border-left: 2px solid;
            margin-top: $hzn-spacing-xxsmall;
            margin-bottom: $hzn-spacing-base;
            font-weight: $hzn-fontweight-base;
            font-family: $hzn-fontfamily-body;
            letter-spacing: normal;
            font-size: 0.875rem; // $hzn-fontsize-body-small
            line-height: 1.25;

            &.visible {
                display: block;
                color: $hzn-color-text-input-placeholder;
            }
        }

        & + .header-footer-store-search-bar {
            border-top: 1px solid $hzn-color-border-neutral-weak;
            color: $hzn-color-border-brand-weak-dark;
            font-size: 12px;
            font-family: inherit;
            font-weight: 700;
            line-height: 16px;

            #header-store-search-header,
            #footer-store-search-header {
                color: $hzn-color-text-input-placeholder;
            }
        }
    }
}
