@use 'sass-mq/mq' as *;
@use '@horizon/tokens/scss/partials/color' as *;
@use '@horizon/tokens/scss/partials/fontFamily' as *;
@use '@horizon/tokens/scss/partials/spacing' as *;
@use 'variables' as *;
@use 'mixins' as *;
@use 'menu' as *;

$FOOTER_PRIMARY_NAV_SECTION_MIN_WIDTH: 145px;

body kmx-global-footer {
    min-height: 1649.5px;
    display: block;
}

@media screen and (min-width: 768px) {
    body kmx-global-footer {
        min-height: 669.5px;
    }
}

#footer {
    // CSS reset, base styles, scoped to #footer
    font-family: $hzn-fontfamily-body;
    background: linear-gradient(225deg, $hzn-color-tone-interactive 0%, $hzn-color-tone-info 100%);
    background-color: $hzn-color-tone-interactive;
    font-size: $BASE_FONT_SIZE;
    color: white;
    position: relative;

    .mobile-hidden {
        @include mq($until:$MOBILE_SIZE) {
            display: none !important;
        }
    }

    .tablet-hidden {
        @include mq($from: $MOBILE_SIZE, $until: 1200px) {
            display: none !important;
        }
    }

    .desktop-hidden {
        @include mq($from: 900px) {
            display: none !important;
        }
    }

    .hidden {
        display: none !important;
    }

    .footer {
        padding: 0 32px 0 24px;
        max-width: $CONTENT_MAX_WIDTH;
        margin: auto;

        &-link,
        #oo-feedback-footer {
            text-decoration: none;
            color: $hzn-color-text-inverse;

            &:focus {
                @include focus-styles($outline-color: white);
            }
        }

        .header-footer-icon-button:not(#footer-store-search-button) {
            background-color: transparent;

            &:focus,
            &.focus {
                @include focus-styles($outline-color: white);
            }
        }

        .header-footer-icon:hover {
            background-color: transparent;
        }

        .header-footer-menu-container {
            top: 0;
            left: 0;
            right: 0;
            position: relative;
        }

        &-social-section {
            height: $FOOTER_NAV_HEIGHT_DESKTOP;
            order: 1;
            display: flex;
            flex: 0 0 45%;
            align-items: center;
            justify-content: flex-start;

            .footer-link {
                margin-right: $hzn-spacing-medium;

                &:nth-child(1) {
                    margin-right: $hzn-spacing-medium;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            @include mq($until: 900px) {
                height: auto;
                margin-bottom: $hzn-spacing-base;
            }
        }

        &-my-store-section {
            position: relative;
            height: $FOOTER_NAV_HEIGHT_DESKTOP;
            order: 2;
            display: flex;
            flex: 0 0 55%;
            align-items: center;
            justify-content: flex-end;
            font-size: $SMALL_FONT_SIZE;

            #footer-my-store-menu {
                color: $hzn-color-text-brand;
                width: $STORE_SEARCH_MENU_WIDTH;
                bottom: 0;
                margin-bottom: $hzn-spacing-base;

                &.header-footer-menu--open {
                    @include header-footer-menu-open();
                }

                @include device-with-hover() {
                    &:hover {
                        @include header-footer-menu-open();
                    }
                }
            }

            #footer-my-store-button {
                .header-footer-icon {
                    fill: white;
                    padding: 0;

                    &:nth-child(1) {
                        margin-right: $hzn-spacing-xsmall;
                    }
                }

                &.footer-my-store-button-alert {
                    .header-footer-icon:first-of-type {
                        width: 24px;
                        height: 25px;
                        border-radius: 0;

                        .header-footer-location-icon-alert-circle {
                            fill: $hzn-color-tone-critical;
                        }
                    }
                }

                #footer-my-store-button-text {
                    margin-right: $hzn-spacing-xsmall;
                }

                @include mq($until: 900px) {
                    height: $FOOTER_NAV_HEIGHT_MOBILE;
                }

                & + .header-footer-menu-container {
                    @include mq($until: 900px) {
                        position: absolute;

                        #footer-my-store-menu {
                            width: 100%;
                            min-width: auto;
                            left: 0;
                            margin-bottom: -8px;
                        }
                    }

                    @include mq($from: 900px) {
                        position: relative;
                    }
                }

                @include device-with-hover() {
                    &:hover {
                        & + .header-footer-menu-container > .header-footer-menu {
                            @include header-footer-menu-open();
                        }

                        &::before {
                            @include mq($until: 900px) {
                                @include header-footer-menu-hover-target($height:30px,$right:0,$top:5px,$width:233px);
                            }

                            @include mq($from: 900px) {
                                @include header-footer-menu-hover-target($height:30px,$right:0,$top:5px,$width:233px);
                            }
                        }
                    }
                }
            }

            @include mq($until: 900px) {
                flex: 0 0 100%;
                height: $FOOTER_NAV_HEIGHT_MOBILE;
                justify-content: space-between;
            }

            // TODO: Remove this styling once we confirm all teams have released to get latest HTML for header/footer
            #footer-call-store-button {
                display: none;
            }
        }

        &-section-divider {
            display: flex;
            flex: 0 0 100%;
            order: 3;
            margin-bottom: $hzn-spacing-large;

            &::before {
                content: '';
                display: block;
                position: absolute;
                right: 0;
                max-width: 100%;
                width: 100%;
                height: 1px;
                opacity: 0.2;
                border-top: 2px solid $hzn-color-tone-info;
            }
        }

        &-primary-navigation {
            flex-direction: row;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            order: 4;
            flex: 0 0 100%;
            margin-bottom: 56px;

            &-section-header {
                font-family: $hzn-fontfamily-display;
                font-size: $LARGE_FONT_SIZE;
                margin: 0 0 $hzn-spacing-xsmall 0;
                letter-spacing: 0.5px;
            }

            &-section {
                min-width: $FOOTER_PRIMARY_NAV_SECTION_MIN_WIDTH;
            }

            &-section-list {
                &-item {
                    list-style: none;
                    margin: 0 0 $hzn-spacing-xsmall 0;
                    padding: 0;
                    line-height: 1.5625;
                }

                .footer-link {
                    font-size: $SMALL_FONT_SIZE;
                }
            }

            @include mq($until: 900px) {
                flex-direction: column;
                margin-bottom: 0;

                &-section {
                    margin-bottom: $hzn-spacing-base;
                }
            }

            @include mq($from: 900px) {
                &-section:not(:last-of-type) {
                    padding-right: $hzn-spacing-xsmall;
                }
            }
        }

        &-secondary-navigation {
            display: flex;
            justify-content: center;
            order: 5;
            flex: 0 0 100%;
            font-size: $XSMALL_FONT_SIZE;
            margin-bottom: $hzn-spacing-base;
            flex-wrap: wrap;

            &-divider {
                padding: 0 $hzn-spacing-base;
            }

            #oo-feedback-footer a {
                text-decoration: none;
                color: inherit;
            }

            @include mq($until: 900px) {
                flex-direction: column;
                margin-bottom: 0;

                .footer-link {
                    margin-bottom: $hzn-spacing-xsmall;
                }

                &-divider {
                    display: none;
                }
            }

            @include mq($from: 900px) {
                line-height: $hzn-spacing-medium;
            }
        }

        &-copyright {
            text-align: left;
            line-height: 18px;
            order: 6;
            flex: 0 0 100%;
            font-size: $XSMALL_FONT_SIZE;
            margin-bottom: $hzn-spacing-medium;
            margin-top: $hzn-spacing-base;

            @include mq($from: 900px) {
                text-align: center;
            }
        }

        &-privacy {
            order: 5;
            margin-bottom: -8px;
            margin-top: 40px;

            @include mq($from: 900px) {
                margin-top: 16px;
            }

            &-text {
                display: block;
                margin-bottom: 24px;
                line-height: 21px;
                font-size: $SMALL_FONT_SIZE;

                @include mq($from: 900px) {
                    font-size: $BASE_FONT_SIZE;
                    text-align: center;
                    line-height: 24px;
                }
            }

            .footer-link {
                text-decoration: underline;
                font-weight: bold;
            }
        }

        @include mq($until: 900px) {
            display: flex;
            flex-wrap: wrap;

            &-social-section {
                order: 4;
            }

            &-store-section {
                order: 1;
            }

            &-horizontal-rule {
                order: 2;
            }

            &-primary-navigation {
                order: 3;
            }

            &-secondary-navigation {
                order: 6;
            }
        }

        @include mq($from: 900px) {
            display: flex;
            flex-wrap: wrap;
        }
    }
}
